import React, { Component } from "react"
import Link from '../link'
import Image from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

export default class CategoryGridItem extends Component {
  static defaultProps = {
    isFeatured: false,
    float: 'left',
    link: '#',
    image: null,
  }

  get wrapperClasses() {
    const classes = ['category-grid__item', `category-grid__item--${this.props.float}`]

    if (this.props.isFeatured) {
      classes.push('category-grid__item--featured')
      classes.push(`category-grid__item--featured-wing-${this.props.float}`)
    }

    return classes.join(' ')
  }

  get itemClasses() {
    const classes = ['category-grid-item']

    if (this.props.isFeatured) {
      classes.push('category-grid-item--featured')
    }

    return classes.join(' ')
  }

  render() {
    return (
      <div className={this.wrapperClasses}>
        <div className="category-grid-item__wrapper">
          <div className={this.itemClasses}>
            <div className="category-grid-item__container">
              <Link href={this.props.link} className="category-grid-item__link" />
              <BackgroundImage tag="figure" fluid={this.props.image?.localFile?.childImageSharp?.fluid} className="category-grid-item__figure">
                <Image fluid={this.props.image?.localFile?.childImageSharp?.fluid} alt={this.props.image?.description} className="category-grid-item__image" />
                <figcaption className="category-grid-item__caption">
                  <strong className="category-grid-item__title">{this.props.children}</strong>
                  {this.props.image?.description ? (
                    <span className="category-grid-item__description">{this.props.image?.description}</span>
                  ) : ''}
                </figcaption>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
