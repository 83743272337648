import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Title from '../components/title'
import Subtitle from '../components/subtitle'
import CategoryGrid from '../components/category-grid'
import CategoryGridItem from '../components/category-grid-item'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomepage {
        customMeta {
          tag
          attributes {
            key
            value
          }
        }
        title
        pageGroups {
          id
          title
          subtitle
          preTitle
          pages {
            ... on ContentfulPage {
              __typename
              id
              title
              link: slug
              featuredImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 854) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            ... on ContentfulPageLink {
              __typename
              id
              title
              link
              featuredImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 854) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        banner {
          ...BannerFragment
        }
      }
    }
  `).contentfulHomepage

  return (
    <Layout banner={data.banner ? (
      <Banner {...data.banner} />
    ) : null}>
      <SEO title={data.title} customMeta={data.customMeta} />
      <div className="container">
        {data.pageGroups.map((group, groupindex) => (
          <section key={group.id} style={{ marginBottom: 80 }}>
            <Title subtitle={group.preTitle} title={group.title} />
            <Subtitle>{group.subtitle}</Subtitle>
            <CategoryGrid>
              {group.pages.map((page, i) => (
                <CategoryGridItem
                  key={page.id}
                  isFeatured={i === 0}
                  float={groupindex % 2 === 0 ? 'left' : 'right'}
                  image={page.featuredImage}
                  link={page.__typename === 'ContentfulPage' ? `/${page.link}` : page.link}
                >
                  {page.title}
                </CategoryGridItem>
              ))}
            </CategoryGrid>
          </section>
        ))}
      </div>
    </Layout>
  )
}

export default IndexPage
