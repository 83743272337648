import React, { Component } from "react"

export default class CategoryGrid extends Component {
  render() {
    return (
      <div className="category-grid__wrapper">
        <div className="category-grid">
          <div className="category-grid__container">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
