import React, { Component } from "react"

export default class Title extends Component {
  static defaultProps = {
    hasSeparator: false,
    title: null,
    subtitle: null,
  }

  get classes() {
    const classes = ['title']

    if (this.props.hasSeparator) {
      classes.push('title--separator')
    }

    return classes.join(' ')
  }

  get subtitle() {
    return this.props.subtitle ? (
      <span className="title__subtitle">{this.props.subtitle}</span>
    ) : null
  }

  get title() {
    return this.props.title ? (
      <span className="title__title">{this.props.title}</span>
    ) : null
  }

  render() {
    return (
      <h2 className={this.classes}>
        {this.subtitle}
        {this.title}
      </h2>
    )
  }
}
