import React, { Component } from "react"

export default class Subtitle extends Component {
  static defaultProps = {
    hasSeparator: false,
    width: 'small',
  }

  get classes() {
    const classes = ['subtitle', `subtitle--${this.props.width}`]

    if (this.props.hasSeparator) {
      classes.push('subtitle--separator')
    }

    return classes.join(' ')
  }

  render() {
    return (
      <h3 className={this.classes}>{this.props.children}</h3>
    )
  }
}
